var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{attrs:{"id":"footer"}},[_c('section',{staticClass:"footer"},[_c('section',{staticClass:"icons"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"href":"https://www.github.com/schollar","target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"icon":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-github")])],1)]}}])},[_c('span',[_vm._v("My Github")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"href":"https://www.linkedin.com/in/cschollar/","target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"icon":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-linkedin")])],1)]}}])},[_c('span',[_vm._v("My LinkedIn")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"href":"https://drive.google.com/file/d/1ZS5bF10NL5S5lurgZ9zZWxUINVSFZ_q8/view?usp=sharing","target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"icon":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file")])],1)]}}])},[_c('span',[_vm._v("My Resume")])])],1),_c('p',{staticClass:"copyright"},[_vm._v(" Colton Schollar "),_c('v-icon',{staticClass:"copyright_icon",attrs:{"size":"18px"}},[_vm._v(" mdi-copyright ")]),_vm._v(_vm._s(_vm.year)+" ")],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }